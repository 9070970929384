.container {
  display: flex;

  .image_wrapper {
    .img {
      object-fit: contain;
      padding: 100px;
      margin: auto;
      max-height: 100vh;
    }
  }
}

.injected_rich_text {
  line-height: 1.3;
}

.markup_wrapper {
  padding: 5%;
}

@media only screen and (max-width: 425px) {
  .container {
    width: 100vw;
    flex-direction: column;
    .image_wrapper {
      width: 100vw;

      .img {
        width: 100vw;
        padding: 90px 20px 30px;
      }
    }
  }
}
