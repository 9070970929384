.box {
  display: block;
  height: 250px;
  width: 250px;
  text-align: center;
  font-weight: 500;
  margin: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.box:hover {
  @media only screen and (min-width: 450px) {
    transform: scale(1.5);
    transition: 0.5s;
    position: relative;
    z-index: 7 !important;
  }
}

.container.projects {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 30px;
  align-items: center;

  .box-gradient-layer {
    margin-bottom: 100px;
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.playground-container {
  width: 90%;
  height: 80%;
}

// BEWARE
@media only screen and (min-width: 425px) {
  .container.projects {
    padding: 50px 0px;
  }
  .playground-container {
    width: 99vw;
  }
}

.sorting-btn {
  cursor: pointer;
  font-size: 14px;
  font-weight: 100;
  height: 100%;
  margin-bottom: 2px;
  padding: 0 10px;
  font-weight: bold;
  font-size: 16px;
}

.sorting-btn-container {
  margin-top: 100px;
  color: #666;
  width: 350px;
  justify-content: center;
}

.sorting-btn.actived {
  color: #666;
  border-bottom: 3.5px solid #4acca5;
  margin-bottom: -1px;
  /* kalau gapake ini, border bakal take space  */
}

.inner-container-button {
  margin: 0.5px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 50px;
}

.font-gede-banget {
  font-size: 60px;
}

.font-anu {
  font-weight: 100;
  font-size: 16px;
}

.inner-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.small-text {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px;
  border-radius: 12px 0 0 0;
  margin: 0;
  font-size: 16px;
  color: white;
  font-weight: 400;
  text-align: center;
  opacity: 0.9;
  line-height: 20px;
  z-index: 5;
}

.box-gradient-layer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2945553221288515) 67%, rgba(0, 0, 0, 0.7231267507002801) 100%);
  height: 250px;
  width: 250px;
  position: absolute;
}

.box-gradient-layer:hover {
  background: none;
}

* {
  box-sizing: border-box;
}
