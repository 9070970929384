@media only screen and (max-width: 425px) {
  .container.project-single .floating-par {
    // position: static;
    max-width: unset;
    padding: 0;
    height: unset;
  }

  .container.project-single .right {
    width: unset;
  }

  .container.project-single .left {
    padding: 2%;
  }

  .container.project-single .single-img {
    width: 100%;
  }

  .sorting-btn-container {
    margin: 50px 0;
  }
}
