@media only screen and (max-width: 411px){
  // smaller phone
  .nav-container {
    .inner-nav-container {
      padding: 20px 10px 0px 10px;
      a {
        // font-weight: 800;
        font-size: 13px;
      }
    }
  }
}