@font-face {
  font-family: Hubbali;
  src: url(assets/fonts/Hubballi-Regular.ttf);
}

@font-face {
  font-family: Oswald;
  src: url(assets/fonts/Oswald-Regular.ttf);
}
@font-face {
  font-family: Oswald-Bold;
  src: url(assets/fonts/Oswald-Bold.ttf);
}
@font-face {
  font-family: Oswald-ExtraLight;
  src: url(assets/fonts/Oswald-ExtraLight.ttf);
}
@font-face {
  font-family: Oswald-Light;
  src: url(assets/fonts/Oswald-Light.ttf);
}

@import 'stylesheets/Projects.scss';
@import 'stylesheets/ProjectsPhone.scss';

@import 'stylesheets/Project.scss';
@import 'stylesheets/ProjectPhone.scss';

@import 'stylesheets/Section.scss';

@import 'stylesheets/NavList.scss';
@import 'stylesheets/NavListPhone.scss';

@import 'stylesheets/Journal.scss';
@import 'stylesheets/JournalPhone.scss';

@import 'stylesheets/Contact.scss';
@import 'stylesheets/ContactPhone.scss';

@import 'stylesheets/About.scss';
@import 'stylesheets/Company.scss';

* {
  font-family: Hubbali !important;
}

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #f5f5f5;
  background: white;
}
::-webkit-scrollbar-thumb {
  background: rgb(205, 205, 205);
  background-color: #666;
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(141, 141, 141);
}

* {
  font-family: Oswald;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background-color: white;
}

.text-sm {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.journal {
  color: #9d9fa1;
}

.journal .bubble p:hover {
  color: black !important;
}

.scrollbar-hide {
  overflow: hidden;
}

button.learn-more {
  margin: 0px;
  padding: 0px;
  border: none;
  background: #4acca5;
  cursor: pointer;
  width: 150px;
  height: 30px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-top: 40px;
  box-sizing: border-box;
  text-align: center;
}

.filter-item {
  margin-right: 15px;
}

.fullscreen-bg {
  position: absolute;
  top: -300px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  @media only screen and (max-width: 500px) {
    top: 0;
  }
}

video {
  position: absolute;
  width: 100%;
  object-fit: cover;
  min-height: 100%;
}

.main,
.mainBoxes,
.mainClose {
  width: 100%;
  height: 110%;
  max-height: 880px;
  overflow: hidden;
  position: absolute;

  div {
    width: 100%;
    height: 110%;
    max-height: 880px;
    overflow: hidden;
    position: absolute;
  }

  .zepto_markup_wrapper {
    top: 12vh;
    left: 3vw;
    font-weight: 100;
    font-size: 14px;
    text-align: justify;
    color: #444;

    p {
      width: 30vw;
    }
  }
}

.spinner {
  margin: 35vh 47vw;
  -webkit-animation: spin 0.3s linear infinite;
  -moz-animation: spin 0.3s linear infinite;
  animation: spin 0.3s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.running_text {
  font-family: Helvetica !important;
  position: absolute;
  color: white;
  font-size: 90px;
  z-index: 9999;
  top: 500px;
  right: 100px;
  font-weight: 1000;
  transition: margin 0.5s ease-in-out, transform 0.5s ease-in-out, max-width 0.5s ease-in-out;
  transition-delay: 5s; // ini sama aja kyk ngasih timeout
  @media only screen and (max-width: 500px) {
    top: 20px;
    right: 0px;
    font-size: 50px;
    color: black;
  }
}

.image_hall {
  width: 100vw;
  min-width: 100vw;
  display: none;
  @media only screen and (max-width: 500px) {
    transform: scale(2.5) translateY(117px) translateX(-8px);
  }
}

.mobile_image_hall {
  height: 100vh;
  animation: zoom-in-zoom-out 15s ease-in infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1.2, 1.2);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1.2, 1.2);
  }
}

#problem1 {
  margin: 90px 0 0 0;
  font-weight: 900;
  font-size: 35px;
  font-family: 'Oswald-Bold' !important;
}
#problem2 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 100;
  font-family: 'Oswald-Light' !important;
}
