.container {
  display: flex;
  align-items: center;
}

.img {
}

.txt {
  margin: 10px;
}
