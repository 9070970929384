@media only screen and (max-width: 425px) {
  /*Small smartphones*/
  .container.projects {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-items: center;
    padding: 0px 60px;
  }

  .container.projects .inner-container-button {
    width: unset;
  }

  .box {
    display: block;
    height: 60vw;
    width: 60vw;
    text-align: center;
    font-weight: 500;
    margin: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  .box-gradient-layer {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.2945553221288515) 67%, rgba(0, 0, 0, 0.7231267507002801) 100%);
    height: 60vw;
    width: 60vw;
    position: absolute;
  }

  .font-gede-banget {
    font-size: 30px;
    margin: 88px 0px 0px 0px;
  }
}
