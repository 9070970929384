.container {
  margin: 80px 0 0 0;
}

.imgo {
  width: 65vw;
  margin: 10px 0;
  float: right;
}

.injected_rich_text {
  position: fixed;
  width: 30vw;
  text-align: justify;
  margin: 0 2vw;
}

@media only screen and (max-width: 425px) {
  .container {
    display: flex;
  }

  .asset_list {
    display: flex;
    flex-direction: column;
    margin: 20px;
    max-width: 100vw;

    .single_person {
      margin: 0 0px 50px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100vw;
    }
  }

  .injected_rich_text {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 50vw;
    text-align: justify;
    margin: 50px;
    position: unset;
    @media only screen and (max-width: 425px) {
      width: unset;
      margin-top: 10px;
      margin-left: 5vw;
      margin-right: 5vw;
    }
  }

  .center {
    text-align: center;
    margin: 100px 0 50px 0;
  }

  .img_wrapper {
    object-fit: contain;
    overflow: hidden;
    width: 100vw;
  }

  .imgo {
    width: 10vw;
    height: auto;
    float: none;
  }

  @media only screen and (max-width: 425px) {
    .container {
      display: block;
      margin-top: 70px;
    }
    .asset_list {
      margin: unset;
      .imgo {
        width: 90vw;
      }
    }
  }
}

.video {
  position: static;
  object-fit: unset;
  min-height: unset;
}
