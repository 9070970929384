.container.journal p {
  font-weight: 100;
  font-size: 16px;
}

.container.journal .big-table {
  display: flex;
  margin-top: 100px;
  width: 100%;
  justify-content: center;
}

.container.journal .bemper-kiri {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
}

.container.journal .bemper-kiri img {
  width: 50%;
  position: unset;
}

.container.journal .bemper-kiri p {
  width: 90%;
  text-align: justify;
}

.container.journal .right,
.container.journal .left {
  margin: 1%;
  display: table;
  width: 200px;
}

@media only screen and (max-width: 425px) {
  .container.journal {
    flex-direction: column;
    justify-content: flex-start;
  }

  .container.journal .right,
  .container.journal .left {
    color: black;
    margin: 20px;
    width: 50%;
  }

  .container.journal .bemper-kiri {
    margin-top: 10vh;
  }

  .container.journal .big-table {
    margin: 0;
  }
}