.contact .right-img {
  float: right;
  object-fit: cover;
  height: 100vh;
}

.contact .img-container {
  float: right;
  height: 100%;
  width: 30vw;
  overflow: hidden;
}

.container.contact {
  padding: 0;
  font-weight: 100;
}

.container.contact h3 {
  font-weight: bold;
  font-size: 16px;
}

.text-container {
  display: flex;
}

.form {
  width: 50%;
  padding: 0 20px;
}

form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}

input {
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  border-bottom: 0.5px solid black;
}

input:focus {
  outline: none;
}

textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  border-bottom: 0.5px solid black;
}

textarea:focus {
  outline: none;
}

button {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  border: none;
  background: transparent;
  cursor: pointer;
}

.address {
  width: 50%;
  padding: 0 20px;
  box-sizing: border-box;
}

.contact-us {
  margin-left: 20px;
}

.p_y_20 {
  padding: 0 3vw !important;
}

.sss {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mt100 {
  margin-top: 100px;
}

.socmed-logo {
  margin-right: 20px;
  cursor: pointer;
}
