.container {
  button {
    span {
      font-family: 'Oswald' !important;
    }
  }
  a {
    margin: auto;
  }
  display: flex;
  flex-direction: column;
  padding-top: 70px;
  background: #000;
  h1,
  h2,
  h3 {
    color: white;
    text-align: center;
    margin: 60px auto 20px;
    font-family: 'Oswald' !important;
  }
  .bigger {
    font-size: 70px !important;
  }
  p {
    color: white;
  }

  hr {
    height: 5px;
    width: 70px;
    background: #4acca5;
    border: none;
  }

  section.first {
    display: flex;
    align-items: center;
    margin: 0 90px;
    @media only screen and (max-width: 425px) {
      margin-top: 50px;
      img {
        display: none;
      }
    }
    h1 {
      text-align: left;
      font-size: 60px;
      margin: 0px;
      border-bottom: none;
    }
    p {
      line-height: 20px;
      font-size: 20px;
    }
  }

  .left {
    .img {
      object-fit: contain;
      padding: 100px;
      margin: auto;
      max-height: 80vh;
    }
  }
}

.right {
  padding: 0 5% 0 0;
}

@media only screen and (max-width: 425px) {
  .container {
    width: 100vw;
    flex-direction: column;
    .image_wrapper {
      width: 100vw;

      .img {
        width: 100vw;
        padding: 90px 20px 30px;
      }
    }
  }
}

.portofolio_container {
  background: #000;
  width: 100%;
  display: grid;
  /*Using the full width/height of the designated box elements, use fr as in example below */
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  grid-gap: 10px;

  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 60px;

  @media only screen and (max-width: 425px) {
    display: flex;
    flex-direction: column;
  }

  div {
    padding: 40px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
    border-radius: 10px;
    text-align: center;

    grid-column: span 2;
  }
}

.portofolio_container {
  grid-template-columns: repeat(6, 1fr);
  div {
    height: 250px;
    padding: 0;
    overflow: hidden;
    cursor: pointer;
  }

  .image_container {
    margin: 0;
    .img {
      object-fit: contain;
      padding-top: 15px;
      min-height: 100%;
      width: 100%;
    }
  }
}

button.learn_more {
  margin: 0px;
  border: none;
  background: red;
  background: #4acca5;
  cursor: pointer;
  color: white;
  font-size: 20px;
  font-weight: 700;
  box-sizing: border-box;
  text-align: center;
  width: 200px;
  transition: 500ms;
  padding: 30px 0;
  font-family: Oswald !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #4acca5;
    background: white;
  }
}

.full_title {
  @media only screen and (max-width: 425px) {
    margin: 60px 5% !important;
  }
}

.dark_container {
  background: white;
  width: 100%;
  display: grid;
  /*Using the full width/height of the designated box elements, use fr as in example below */
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  grid-gap: 10px;

  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  padding: 60px;
  background: black;
  // background: rgb(34, 61, 97);
  // background: linear-gradient(145deg, rgba(34, 61, 97, 1) 0%, rgba(18, 84, 115, 1) 100%);
  // background: rgb(3, 31, 68);
  // background: linear-gradient(145deg, rgba(3, 31, 68, 1) 0%, rgba(0, 35, 51, 1) 100%);
  display: flex;
  @media only screen and (max-width: 425px) {
    align-items: center;
    flex-direction: column;
  }
  p {
    color: white;
  }

  div {
    padding: 20px;
    width: 180px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    text-align: center;

    grid-column: span 2;
  }
}
.cost_fee {
  background: white;
  width: 100%;
  display: grid;
  /*Using the full width/height of the designated box elements, use fr as in example below */
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  grid-gap: 10px;

  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background: black;
  display: flex;
  @media only screen and (max-width: 425px) {
    flex-direction: column;
  }
  p {
    color: white;
  }

  div {
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    text-align: center;

    grid-column: span 2;
  }
}

.bumper_1 {
  height: 150px;
}

.bumper_2 {
  height: 80px;
}

.three_row {
  p {
    color: black;
  }
}

.three_row:hover {
  @media only screen and (min-width: 450px) {
    transform: scale(1.3);
    transition: 0.7s;
    position: relative;
    z-index: 7 !important;
  }
  img:hover {
    @media only screen and (min-width: 450px) {
      transform: scale(1.2);
      transition: 0.7s;
      position: relative;
      z-index: 7 !important;
    }
  }
}
