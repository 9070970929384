a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

ul {
  padding: 0;
  li {
    position: relative;
  }
}

.nav-container,
.nav-container2 {
  z-index: 20;
  width: 100%;
  position: fixed;
  top: 0;
  @media only screen and (max-width: 425px) {
    font-size: 13px;
    background: rgb(255, 255, 255);

    ul {
      padding: 0 0 2px 0;
    }
  }
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 6%, rgba(255, 255, 255, 0.3897934173669467) 54%, rgba(255, 255, 255, 1) 94%);
  a {
    font-weight: 100;
  }

  .inner-nav-container {
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 0px 20px;

    ul.right {
      display: flex;
      text-decoration: none;
      list-style-type: none;
      width: fit-content;
      justify-content: space-around;

      li {
        padding: 0px 6px;
      }
    }
  }
}
