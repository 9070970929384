@media only screen and (max-width: 425px) {
  .contact .right-img {
    display: none;
  }

  .img-container {
    display: none;
  }

  .text-container {
    flex-direction: column;
    margin: 500px 0px 0px 0px;
    padding: 0px;
  }

  .address,
  .form {
    width: 100%;
    margin-bottom: 100px;
  }

  .socmed-logo-container {
    display: flex;
    justify-content: center;
  }

  .container.contact {
    padding: 0px 15px;
  }

  .container.contact h3 {
    text-align: center;
  }
}