.inner_img {
  // width: 100%;
  // height: 100%;
  // object-fit: cover;
}

.small_text {
  position: absolute;
  bottom: 50%;
  left: 50%;
  translate: -50%;
  padding: 12px;
  border-radius: 12px 0 0 0;
  margin: 0;
  font-size: 34px;
  font-weight: bolder;
  text-align: center;
  opacity: 0.9;
  line-height: 20px;
  z-index: 1;
  color: white;
  text-shadow: -1px 2px 14px rgba(0, 0, 0, 0.9);
  // box-shadow: 0px 0px 18px 7px rgba(0, 0, 0, 0.87);
}

.parent {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.mid_container_raja {
  margin-top: 67px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 67px);
}

.mid_container {
  justify-content: space-evenly;
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 60px;
  text-align: center;
  margin: 20px 0 50px 0;
}

.box_gradient_layer {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2945553221288515) 67%, rgba(0, 0, 0, 0.7231267507002801) 100%);
  position: absolute;
  transition: 0.5s;
}

.box_gradient_layer:hover {
  background: none;
}

@media only screen and (max-width: 425px) {
  .title {
    font-size: 30px;
  }

  .mid_container {
    flex-direction: column;
  }

  .parent {
    max-width: 100vw;
    overflow-x: hidden;
  }
}
