.container {
  margin: 0 50px;
}

.board_member {
  display: flex;
  justify-content: space-around;
  margin: 20px;
  max-width: 100vw;
  flex-wrap: wrap;

  .single_person {
    margin: 0 10px 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .name {
      margin-bottom: 5px;
      font-size: large;
      font-weight: 900;
    }

    .position {
      margin: 0;
    }
  }
}

.injected_rich_text {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 210px;
  width: 300px;
  text-align: justify;
}

.center {
  text-align: center;
  margin: 100px 0 50px 0;
}
