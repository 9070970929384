.container.about p,
i {
  text-align: justify;
  font-weight: 100;
}

.container.about {
  align-items: flex-start;
  padding: 0px;
  display: flex;
  flex-direction: column;

  .up1,
  .up2 {
    display: flex;
  }

  .up2 {
    flex-direction: row-reverse;
  }
}

.about .right {
  margin: 5% 2%;
}

.container.about img {
  width: 50vw;
  object-fit: contain;
}

.container.about .img-container {
  height: 100%;

}

@media only screen and (max-width: 425px) {
  .container.about img {
    display: none;
  }

  .container.about .img-container {
    display: none;
  }

  .about .right {
    margin: 7vh 20px 20px 20px !important;
    width: 100%;
    /* margin-top: 400px; */
  }
}