.single-img {
  float: right;
  margin-bottom: 20px;
}

.container.project-single {
  height: unset;
  flex-direction: column;
  align-items: flex-end;

  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    background: #f5f5f5;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(205, 205, 205);
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(141, 141, 141);
  }
}

.container.project-single p {
  font-weight: 100;
  font-size: 14px;
  text-align: justify;
  color: #444;
  margin: 10px;
  b {
    height: 20px;
  }
}

.project-single .img-container {
  color: red;
  display: flex;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}

.project-single .img-container img {
  margin: 30px;
}

.project-single .left {
  width: 100%;
  padding: 50px;
}

.floating-par {
  position: fixed;
  max-width: 40%;
  left: 0;
  top: 70px;
  padding-left: 3%;
  padding-right: 1%;
  height: 80vh;
  overflow: auto;
  p {
    min-height: 10px;
  }
}

.left {
  float: left;
}

.right {
  /* float: right; */
  width: 50%;
}
